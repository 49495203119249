import "./current.user.menu.css"
import { useState, useEffect } from "react"
import Package from "@/v1-ui/Package"
import cn from "@/v1-ui/utils/utils.cn"
import useSidebarContext from "@/v1-ui/Sidebar/sidebar.context"
import Button from "@/v1-ui/Button"
import Popover, { type PopoverProps } from "@/v1-ui/Popover"
import LineLoader from "@/v1-ui/LineLoader"
import UserAvatar from "@/v1-console/user/UserAvatar"
import { getUserFullName } from "@/v1-console/user/user.utils"
import { useAppContext } from "@/v1-console/app/context/AppContextProvider"
import { useAppContextQueryIsLoading }
  from "@/v1-console/app/context/app.context.query"
import { trackCurrentUserMenuShown }
  from "@/v1-console/currentUser/currentUser.tracking"
import CurrentUserMenuContent
  from "@/v1-console/currentUser/CurrentUserMenuContent"
import type { CurrentUserFragment }
  from "@/v1-console/currentUser/__types__/currentUser.fragment"

export type CurrentUserMenuProps = Omit<PopoverProps, "content">

function CurrentUserMenu(props: CurrentUserMenuProps) {
  const { currentUser } = useAppContext()
  const { isMini } = useSidebarContext()
  const [ isShown, setIsShown ] = useState(false)
  const [ userTenants, setUserTenants ] = useState<CurrentUserFragment["UserTenants"]>([])
  const [ active, setActive ] = useState<CurrentUserFragment["UserTenants"][0]>(null)
  const appLoading = useAppContextQueryIsLoading()

  useEffect(() => {
    if(!currentUser) return
    const uts = currentUser.UserTenants
    if(!uts || uts.length === 0) return

    const id = currentUser.ActiveTenantId
    const rest = []
    let active = uts[0]

    for(const ut of uts) {
      if(ut.TenantId === id) active = ut
      rest.push(ut)
    }

    if(rest.length > 0) {
      setUserTenants(rest)
    }

    if(active) {
      setActive(active)
    }
  }, [
    currentUser
  ])

  return (
    <Popover
      shown={isShown}
      placement="right"
      isAutoCloseDisabled
      isShownIndicated={false}
      contentClassName="current-user-menu relative w-[186px] p-2 overflow-y-auto"
      content={
        <CurrentUserMenuContent
          userTenants={userTenants}
          active={active}
          onClose={() => {
            setIsShown(false)
          }}
        />
      }
      {...props}
    >
      <Button
        className="current-user-menu__toggle"
        aria-label="Åpne brukermenyen"
        onClick={() => {
          const value = !isShown
          setIsShown(value)
          if(value) trackCurrentUserMenuShown()
        }}
      >
        <UserAvatar
          isLoading={appLoading.isLoading}
          user={currentUser}
          className={cn("current-user-menu__avatar", {
            "current-user-menu__avatar--mini": isMini
          })}
        />

        {isMini
          ? null
          : <div className="current-user-menu__user-info">
              <LineLoader
                className="truncate w-full"
                loadingClassName="mt-0.5"
                height={12}
                loading={appLoading.isLoading}
              >
                {getUserFullName(currentUser)}
              </LineLoader>

              <LineLoader
                className="truncate w-full opacity-50 text-xs leading-none mt-0.5"
                loadingClassName="!opacity-100 mt-1.5 mb-0.5"
                height={8}
                loading={appLoading.isLoading}
              >
                {active?.Tenant?.Name}
              </LineLoader>

              <div className="flex items-center mt-1 text-xs">
                {appLoading.isLoading
                  ? <>
                      <div className="bg-loading h-2 w-3 mr-1" />
                      <div className="bg-loading h-2 flex-1" />
                    </>
                  : <Package.Info
                      iconClassName="current-user-menu__package-info--icon"
                      packageCode={currentUser?.Package?.Code}
                      packageName={currentUser?.Package?.Name}
                      packageIcon={currentUser?.Package?.Icon}
                    />
                }
              </div>
            </div>
        }
      </Button>
    </Popover>
  )
}

export default CurrentUserMenu
