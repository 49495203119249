import { useState } from "react"
import { useApolloClient } from "@apollo/client"
import Button from "@/v1-ui/Button"
import { useNavigate } from "@/v1-console/router/router.utils"
import { logout } from "@/v1-console/auth/auth.instance"
import { useAppContext } from "@/v1-console/app/context/AppContextProvider"
import ErrorMessage from "@/v1-ui/ErrorMessage"
import Link from "@/v1-console/components/Link"
import LinkIcon from "@/v1-console/components/Link/LinkIcon"
import config from "@/v1-console/config"
import { TENANT_SWITCH_EVENT }
  from "@/v1-console/currentUser/currentUser.utils"
import { useAppContextQueryIsLoading }
  from "@/v1-console/app/context/app.context.query"
import { trackCurrentUserMenuActiveTenantChanged }
  from "@/v1-console/currentUser/currentUser.tracking"
import useCurrentUserTenantMutation
  from "@/v1-console/currentUser/tenant/currentUser.tenant.mutation"
import CurrentUserTenantItem
  from "@/v1-console/currentUser/tenant/CurrentUserTenantItem"
import CurrentUserTenantPicker
  from "@/v1-console/currentUser/tenant/CurrentUserTenantPicker"
import type { CurrentUserFragment }
  from "@/v1-console/currentUser/__types__/currentUser.fragment"

export type CurrentUserMenuContentProps = {
  isMini?: boolean,
  userTenants: CurrentUserFragment["UserTenants"],
  active: CurrentUserFragment["UserTenants"][0],
  onClose?(): void
}

function CurrentUserMenuContent(props: CurrentUserMenuContentProps) {
  const { isMini, userTenants, active, onClose } = props
  const { events, currentUser } = useAppContext()
  const [ isPickerShown, setIsPickerShown ] = useState(false)
  const [ mutate, { error, loading }] = useCurrentUserTenantMutation()
  const navigate = useNavigate()
  const client = useApolloClient()

  const isAdmin = currentUser?.IsAdminOfActiveTenant
  const appLoading = useAppContextQueryIsLoading()

  return (
    <>
      <Button
        small
        className="current-user-menu__close absolute top-px right-px z-10"
        iconSrc="cross"
        iconSize={8}
        onClick={onClose}
      />

      {isPickerShown
        ? <>
            <div className="flex items-center h-[42px]">
              <Button
                small
                className="current-user-menu-tenant__close-picker"
                iconSrc="angle-left"
                iconSize={11}
                onClick={() => {
                  setIsPickerShown(false)
                }}
              />

              <div className="ml-1">
                Bytt medlemskap
              </div>
            </div>

            <hr className="bg-bg2 h-px my-1" />
          </>
        : null
      }

      {isPickerShown
        ? <CurrentUserTenantPicker
            isMini={isMini}
            userTenants={userTenants}
            activeUserTenant={active}
            onChange={(v) => {
              setIsPickerShown(false)
              if(v.TenantId === active.TenantId) return
              mutate({
                variables: {
                  input: {
                    tenantId: v.TenantId
                  }
                },
                onCompleted() {
                  events.dispatchEvent(new Event(TENANT_SWITCH_EVENT))
                  // Ensure all components are closed during the event above
                  // because apollo will automatically refetch all cached queries
                  setTimeout(() => {
                    client.resetStore()
                  }, 50)
                  trackCurrentUserMenuActiveTenantChanged()
                }
              })
            }}
          />
        : <>
            <CurrentUserTenantItem
              className="is-active"
              tenantClassName="truncate"
              isMini={isMini}
              userTenant={active}
              aria-label="Aktiv tenant"
              loading={loading || appLoading.isLoading}
              onClick={() => {
                setIsPickerShown(!isPickerShown)
              }}
            />

            <ErrorMessage
              error={error}
            />

            <hr className="bg-bg1 h-px my-1" />

            <LinkIcon
              className="w-full justify-start px-3 py-1 text-fg1"
              to="https://placepoint.no/oppgrader"
              target="_blank"
              iconClassName="ml-auto my-auto"
            >
              <img
                className="-ml-px mr-1.5 w-5 h-5"
                src={currentUser?.Package?.Icon}
                alt="Abonnementstype"
              />

              Abonnement
            </LinkIcon>

            <Button
              className="current-user-menu__to-profile w-full justify-start px-3"
              iconSrc="user"
              onClick={() => {
                navigate("/map/user/profile")
                onClose()
              }}
            >
              Rediger profil
            </Button>

            <Button
              className="current-user-menu__to-activities w-full justify-start px-3"
              iconSrc="time-past"
              onClick={() => {
                navigate("/map/user/activities")
                onClose()
              }}
            >
              Siste personoppslag
            </Button>

            {isAdmin
              ? <Button
                  className="current-user-menu__to-api w-full justify-start px-3"
                  iconSrc="rocket"
                  onClick={() => {
                    navigate("/map/user/api-client")
                    onClose()
                  }}
                >
                  API tilgang
                </Button>
              : null
            }

            <hr className="bg-bg1 h-px my-1" />

            <Button
              className="current-user-menu__logout w-full justify-start px-3"
              iconSrc="power"
              onClick={() => {
                logout()
              }}
            >
              Logg ut
            </Button>

            <hr className="bg-bg1 h-px my-1" />
          </>
      }

      {isPickerShown
        ? null
        : <div className="mt-2 text-xs text-fg0 text-center flex flex-col items-center">
            <Link to={config.thirdPartyCredits}>
              Programvarelisenser
            </Link>

            <div className="text-[10px] leading-none">
              {config.version}
            </div>
          </div>
      }
    </>
  )
}

export default CurrentUserMenuContent
