import { useEffect } from "react"
import type { QueryHookOptions } from "@apollo/client"
import { makeVar, useReactiveVar, useQuery } from "@apollo/client"
import query from "./app.context.query.gql"
import type { AppContextQuery, AppContextQueryVariables }
  from "./__types__/app.context.query"

const appContextLoadingVar = makeVar({
  isLoading: false,
  isPolling: false
})

type Options = QueryHookOptions<
  AppContextQuery,
  AppContextQueryVariables
>

function useAppContextQuery(options?: Options) {
  const res = useQuery<
    AppContextQuery,
    AppContextQueryVariables
  >(query, options)

  useEffect(() => {
    appContextLoadingVar({
      isLoading: res.networkStatus === 1, // loading
      isPolling: res.networkStatus === 6 // polling
    })
  }, [
    res.networkStatus
  ])

  return res
}

export function useAppContextQueryIsLoading() {
  return useReactiveVar(appContextLoadingVar)
}

export default useAppContextQuery
