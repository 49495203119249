import { useMemo } from "react"
import { logWarning } from "@/v1-ui/utils/utils.log"
import { useNotifications } from "@/v1-ui/Notification"
import AppContextErrorNotification
  from "@/v1-console/app/context/AppContextErrorNotification"
import useAppContextQuery from "@/v1-console/app/context/app.context.query"
import { registerLayerServiceTokens }
  from "@/v1-console/map/auth/map.auth.utils"
import { ONE_HOUR_IN_MS } from "@/v1-console/constants"
import { identify } from "@/v1-console/analytics"
import { setUser } from "@/v1-console/sentry"
import { groupFeature2WithValueTypeByApiName }
  from "@/v1-console/feature/feature.utils"
import AppContextProvider, {
  type AppContextProviderProps,
  APP_CTX_LOADED_EVENT,
  DEFAULT_APP_CTX,
  EVENTS
} from "@/v1-console/app/context/AppContextProvider"

export type AppContextLoaderProps = Omit<AppContextProviderProps, "value">

function AppContextLoader(props: AppContextLoaderProps) {
  const { addNotification } = useNotifications()

  const { data, refetch } = useAppContextQuery({
    notifyOnNetworkStatusChange: true,
    pollInterval: ONE_HOUR_IN_MS,
    variables: {
      layerTokensInput: {
        referer: window.location.origin
      }
    },
    onCompleted(d) {
      registerLayerServiceTokens(d.getServiceTokens)

      setUser(d.getCurrentUser)
      identify(d.getCurrentUser)

      // @note: could add context as event payload if needed
      EVENTS.dispatchEvent(new CustomEvent(APP_CTX_LOADED_EVENT))
    },
    onError(e) {
      const dismiss = addNotification({
        timeout: false,
        intent: "danger",
        title: "En feil oppstod med autentiseringen",
        iconSrc: "exclamation",
        content: (
          <AppContextErrorNotification
            error={e}
            onRetry={() => {
              refetch()
              dismiss()
            }}
            onDismiss={() => {
              dismiss()
            }}
          />
        )
      })
    }
  })

  const context = useMemo(() => {
    if(!data) return DEFAULT_APP_CTX
    const layerServerToken = data
      .getServiceTokens
      .find((t) => t.ServerUrl.includes("placepoint.no"))
      ?.Token

    if(!layerServerToken) {
      logWarning("Unable to find the token for placepoint layer server")
    }

    return {
      currentUser: data.getCurrentUser,
      events: DEFAULT_APP_CTX.events,
      features: groupFeature2WithValueTypeByApiName(data.getFeatures2WithValueType),
      layerServerToken
    }
  }, [
    data
  ])

  return (
    <AppContextProvider
      value={context}
      {...props}
    />
  )
}

export default AppContextLoader
